// Email functionality
const constructEmail = e => {
  e.preventDefault();
  const user = 'asopticssg';
  const domain = 'gmail.com';
  window.location.href = 'mailto:' + user + '@' + domain;
};

// Modal functionality
const MAP_URL =
  'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1994.3923266569682!2d103.8333908!3d1.3042498!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da19926d40711d%3A0x4e6723a2f6c6fbfb!2sAS%20Optics!5e0!3m2!1sen!2ssg!4v1732793197627!5m2!1sen!2ssg';

const openModal = () => {
  const modal = document.getElementById('mapModal');
  const mapFrame = document.getElementById('mapFrame');
  if (modal && mapFrame) {
    modal.classList.add('is-open');
    document.body.style.overflow = 'hidden';
    mapFrame.src = MAP_URL;
  }
};

const closeModal = () => {
  const modal = document.getElementById('mapModal');
  const mapFrame = document.getElementById('mapFrame');
  if (modal && mapFrame) {
    modal.classList.remove('is-open');
    document.body.style.overflow = 'auto';
    mapFrame.src = '';
  }
};

// Make closeModal available globally for the onclick attribute
window.closeModal = closeModal;

// Add event listener when DOM is loaded
document.addEventListener('DOMContentLoaded', () => {
  const emailLink = document.getElementById('email-link');
  const mapLink = document.getElementById('map-link');
  const modal = document.getElementById('mapModal');
  const closeButton = document.querySelector('.modal-close');

  if (emailLink) {
    emailLink.addEventListener('click', constructEmail);
  }

  if (mapLink) {
    mapLink.addEventListener('click', e => {
      e.preventDefault();
      openModal();
    });
  }

  if (closeButton) {
    closeButton.addEventListener('click', closeModal);
  }

  // Close modal when clicking outside the iframe
  if (modal) {
    modal.addEventListener('click', e => {
      if (e.target === modal) {
        closeModal();
      }
    });
  }
});
